/* src/About/AboutPage.css */
body {
  padding-top: 70px; /* Ajustați valoarea în funcție de înălțimea navigation bar */
}
.about-container {
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .about-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #a1722b;
  }
  
  .about-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .about-text {
    flex: 1;
    margin-right: 20px;
  }
  
  .about-text p {
    font-size: 1.2em;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .about-image {
    flex: 1;
    text-align: center;
  }
  
  .about-image img {
    max-width: 80%;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  