.product-management {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .product-management h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .product-management form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .product-management input {
    flex: 1 1 calc(33% - 40px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .product-management button {
    padding: 10px 20px;
    background-color: #a1722b;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .product-management button:hover {
    background-color: #845a1e;
  }
  
  .product-management ul {
    list-style: none;
    padding: 0;
  }
  
  .product-management li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .product-management li button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .product-management li button:hover {
    background-color: #c9302c;
  }
  