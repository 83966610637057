.product-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 10px;
  gap: 15px;
}

.cart-wrapper {
  padding-top: 60px;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 100px;
}

.cart-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding-top: 20px;
  padding-left: 50px;
}

.cart-total {
  font-weight: bold;
  font-size: 20px;
}
