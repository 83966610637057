.signup-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}

.signup-form {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.signup-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.signup-form label {
  display: block;
  margin-bottom: 10px;
}

.signup-form .password-container {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding-right: 10px;
}

.signup-form input,
.signup-form select {
  flex: 1;
  padding: 10px;
  /* border: none; */
  border-radius: 5px;
  outline: none;
}

.signup-form .password-label {
  position: relative;
}

.signup-form .password-toggle {
  margin-left: 10px;
  cursor: pointer;
  z-index: 2;
}

.signup-form .generate-password {
  display: block;
  text-align: right;
  margin-top: 5px;
  font-size: 0.9em;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.signup-form button {
  width: 100%;
  padding: 10px;
  background-color: #a1722b;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.signup-form button:hover {
  background-color: #845a1e;
}

.signup-form .password-strength {
  padding-bottom: 20px;
  font-weight: bold;
  color: green;
}

.signup-form .password-strength.weak {
  color: red;
}

.signup-form .password-strength.medium {
  color: orange;
}

.signup-form .password-strength.strong {
  color: green;
}

.role-selection {
  text-align: center;
}

.role-selection h2 {
  margin-bottom: 20px;
}

.role-selection button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #a1722b;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.role-selection button:hover {
  background-color: #845a1e;
}
