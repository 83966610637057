.recommendations {
  padding: 20px;
}

.recommendation-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.product-item {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  width: 200px;
  box-sizing: border-box;
  text-align: center;
}

.product-item h3 {
  font-size: 16px;
  margin: 10px 0;
}

.product-item p {
  margin: 5px 0;
}
