.pointer {
  cursor: pointer;
}

.productItem {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  width: calc(25% - 20px); /* Four items per row with 20px gap */
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.productImage {
  width: 100%;
  object-fit: cover; /* Cover the image to fit within the dimensions */
}

.productItem h3 {
  font-size: 16px;
  margin: 10px 0;
}

.productItem p {
  font-size: 14px;
  margin: 10px 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add space between buttons */
}
