
.community-container {
    padding: 20px;
}

.community-header {
    text-align: center;
    margin-bottom: 20px;
}

.community-header h1 {
    margin: 0;
    color: #a1722b;
}

.community-header p {
    margin: 0;
    color: #333;
}
