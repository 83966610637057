.full-width {
  width: 100%;
}
.default {
  background-color: #a1722b;
  font-family: "Kanit", sans-serif;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}
.default:hover {
  background-color: #b37b3d;
}

.default:disabled {
  background-color: #676767;
  color: rgba(255, 255, 255, 0.7);
}

.nav-button {
  margin-right: 10px;
  font-size: 20px;
  padding: 0px;
  border: none;
}
