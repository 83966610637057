.navbar-container {
  margin-top: 54px;
}

.navigation-bar {
  background-color: #a1722b;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  overflow: auto;
  /* padding: 5px 0; */
  z-index: 20;
  top: 0;
}

.navigation-align-left {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.navigation-align-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

.navigation-link {
  color: black;
  text-decoration: none;
  margin: 0 10px;
  align-items: center;
  display: flex;
}
.navigation-link p {
  font-size: 20px;
}

.header-title {
  font-size: 30px;
}

/* NavigationBar.css */

.cart-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.cart-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: rgba(255, 0, 0, 0.447);
  color: white;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
}

.wishlist-icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

.wishlist-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
}
