/* src/Sign In/SignInSignUpPage.css */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 56px);
  background-color: #f0f0f0;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-buttons button {
  background: none;
  border: none;
  color: #a1722b;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
}

.toggle-buttons .active {
  font-weight: bold;
  color: #b37b3d;
}

.form-wrapper {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.role-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.role-selection button {
  margin-top: 10px;
  background-color: #a1722b;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.role-selection button:hover {
  background-color: #b37b3d;
}

.sign-in-form {
  display: flex;
  flex-direction: column;
}

.sign-in-form input {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
}

.sign-in-form label {
  font-size: 18px;
}
