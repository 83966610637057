.you-may-also-like {
    margin-left: 208px; 
    margin-right: 208px; 
}
.you-may-also-like .slide {
    position: relative;
    width: 200px; 
    height: 300px;
    margin: 0 15px;
}

.you-may-also-like img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.buy-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 0;
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 2;
}

.slide:hover .buy-button {
    opacity: 1;
}

.you-may-also-like .slick-prev,
.you-may-also-like .slick-next {
    font-size: 0; /* Removes the font size to eliminate arrow text */
    color: black;
    width: 30px; /* Control the size of the arrow buttons */
    height: 30px;
    background: transparent;
    z-index: 10;
}

.you-may-also-like .slick-prev {
    left: -45px; /* Moves the left arrow outside the image area */
}

.you-may-also-like .slick-next {
    right: -45px; /* Moves the right arrow outside the image area */
}

.you-may-also-like .slick-prev:before,
.you-may-also-like .slick-next:before {
    content: ''; /* Clears any default text or content */
    font-size: 0; /* Ensures no text size is applied */
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../../Icons/left.png'); 
    background-size: contain;
    background-repeat: no-repeat;
}

.you-may-also-like .slick-next:before {
    background-image: url('../../Icons/right.png'); 
}

