.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message {
  margin: 40px 0;
  text-align: center;
}

.checkmark {
  font-size: 50px;
  color: #27ae60;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

text {
  font-size: 16px;
  color: #777;
}

.orderSummary {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 40px;
  text-align: left;
}

.summaryTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}

.total {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}

.backHomeBtn:hover {
  background-color: #f4f4f4;
}
