body {
    padding-top: 70px; /* Ajustați valoarea în funcție de înălțimea navigation bar */
}

.hero-section {
    position: relative;
    min-height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    justify-content: center; /* Vertically center content */
    background-image: url('../../Pictures/BackgroundHP.jpeg');
    background-size: cover;
    background-position: center;
    text-align: left; /* Text aligned to the left */
    padding-left: 10%; 
    box-sizing: border-box;
    /* overflow-y: scroll; Enable scrolling */
}

.hero-title {
    font-size: 4rem; /* Large font size for visibility */
    background: linear-gradient(to top, #FFFFFF 30%, #544f4f 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: opacity 0.8s ease-out;
    transform: translateX(-20px);
    opacity: 0;
}

.hero-button {
    background-color: rgba(232, 165, 65, 0.5); /* Adjust transparency */
    color: white;
    padding: 12px 48px; /* Adjust padding for button size */
    text-decoration: none;
    border-radius: 0; /* Sharp edges */
    margin-top: 20px;
    font-weight: bold;
    transition: opacity 0.5s ease-out, background-color 0.3s ease;
    cursor: pointer; /* Pointer cursor on hover */
}

/* Animation classes */
.slide-in {
    opacity: 1;
    transform: translateX(0);
}

.hero-button:hover {
    background-color: rgba(232, 165, 65, 0.7); /* Slightly darker on hover */
}

/* Scrollbar styling
.hero-section::-webkit-scrollbar {
    width: 8px;
} */

/* .hero-section::-webkit-scrollbar-track {
    background: #f1f1f1; 
} */
 
/* .hero-section::-webkit-scrollbar-thumb {
    background: #888; 
} */
/* 
.hero-section::-webkit-scrollbar-thumb:hover {
    background: #555; 
} */
