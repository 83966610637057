.product-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #676767;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #fff;
  position: relative;
}

.product-title {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.product-quantity {
  display: flex;
  align-items: center;
  column-gap: 15px;
}
