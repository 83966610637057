.explore-container {
  padding-top: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.did-you-know {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.did-you-know h2 {
  color: #a1722b;
  margin-bottom: 20px;
}

.fact {
  margin-bottom: 20px;
}

.fact h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.fact p {
  font-size: 1.2em;
  line-height: 1.6;
}

.map {
  padding-bottom: 20px;
  height: 50%;
}
