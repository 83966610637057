.seller-dashboard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.seller-dashboard h2 {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-sections {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.section {
  flex: 1 1 calc(33% - 40px);
  max-width: calc(33% - 40px);
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.section h3 {
  margin-bottom: 10px;
}

.section p {
  margin-bottom: 20px;
}

.section button {
  padding: 10px 20px;
  background-color: #845a1e;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.section button:hover {
  background-color: #845a1e;
}
