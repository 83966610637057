.orderItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 400px;
}

.itemImage {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  margin-right: 15px;
}

.itemDetails {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.itemText {
  font-size: 16px;
}

.itemPricing {
  column-gap: 20px;
  row-gap: 10px;
}
