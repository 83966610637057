/* src/pages/InventoryPage.css */

.inventory-page {
    padding: 20px;
  }
  
  .inventory-page h1 {
    margin-bottom: 20px;
  }
  
  .inventory-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .inventory-table th,
  .inventory-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .inventory-table th {
    background-color: #f4f4f4;
    text-align: left;
  }
  
  .inventory-table input {
    width: 60px;
    padding: 5px;
  }
  
  .inventory-table button {
    margin-left: 10px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .inventory-table button:hover {
    background-color: #ddd;
  }
  