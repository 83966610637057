.best-seller .title {
    text-align: left;
    text-transform: uppercase;
    padding-left: 35px;
  }
  
  .product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .product {
    flex-basis: 20%;
    position: relative;
    margin: 10px;
    text-align: center;
  }
  
  .product-name {
    font-size: 20px;
    font-weight: bold; 
    color: black;
    margin-bottom: 5px; /* Space between the name and the price */
  }
  
  .product-price {
    font-size: 18px;
    color: black; 
  }
  
  .product-image-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    background: #f0f0f0;
    overflow: hidden;
  }
  
  .product img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .product:hover .overlay {
    opacity: 1;
  }
  
  .buy-now-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 3px;
    border-radius: 0; /* Sharp edges */
  }

  .more-button{
    padding: 10px 20px;
    display: block;
    color: #ffffff;
    text-decoration: none;
    border-radius: 30px;
    font-size: 20px;
    white-space: nowrap;
  }

  
  .special .overlay {
    opacity: 1; /* Always visible */
    animation: slideText 5s linear infinite;
    align-items: flex-end; /* Align text at the bottom */
  }
  
  @keyframes slideText {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  