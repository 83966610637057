.contact-container {
  font-family: "Arial, sans-serif";
  color: #a1722b;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f5f5f5;
  height: calc(100vh - 56px);
}

.contact-container h1 {
  font-size: 36px;
  margin-bottom: 30px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0 20%;
}

.contact-form label {
  font-size: 20px;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  display: inline-block;
}
