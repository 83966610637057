.seller-registration-form {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 600px;
  margin: 0 auto;
}

.seller-registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
}
.seller-registration-form .company-group {
  padding: 10px 0px;
  border-top: 2px solid #845a1e;
}

.seller-registration-form .form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.seller-registration-form label {
  display: block;
  margin-bottom: 5px;
}

.seller-registration-form .password-container {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding-right: 10px;
}

.seller-registration-form input,
.seller-registration-form select {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  outline: none;
}

.seller-registration-form .password-label {
  position: relative;
}

.seller-registration-form .password-toggle {
  margin-left: 10px;
  cursor: pointer;
  z-index: 2;
}

.seller-registration-form .generate-password {
  display: block;
  text-align: right;
  margin-top: 5px;
  font-size: 0.9em;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.6);
}

.seller-registration-form button {
  width: 100%;
  padding: 10px;
  background-color: #a1722b;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.seller-registration-form button:hover {
  background-color: #845a1e;
}

.seller-registration-form .password-strength {
  margin-top: 10px;
  font-weight: bold;
  color: green;
}

.seller-registration-form .password-strength.weak {
  color: red;
}

.seller-registration-form .password-strength.medium {
  color: orange;
}

.seller-registration-form .password-strength.strong {
  color: green;
}
