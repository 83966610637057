.order-management-container {
  padding-top: 60px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.order-management-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.order-list {
  margin-top: 20px;
}

.order-item {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.order-item h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.order-item p {
  font-size: 16px;
  margin-bottom: 10px;
}

.order-item button {
  padding: 8px 16px;
  background-color: #a1722b;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.order-item button:hover {
  background-color: #845a1e;
}
