
.donation-container {
    background-color: #f5d0a9;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    margin: 20px auto;
    width: 80%;
}

.donation-header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}

.donation-header p {
    font-size: 1.2em;
    margin-bottom: 30px;
}

.vinyl {
    margin: 20px auto;
    width: 200px;
    height: 200px;
    background: url('vinyl.png') no-repeat center center/cover;
    border-radius: 50%;
    animation: spin 5s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.donation-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.button.donate-button {
    background-color: #a1722b;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    width: auto; /* Ensure width is auto */
    max-width: 100%;
}

.button.donate-button:hover {
    background-color: #7e5520;
}

.donation-questions h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
}

.questions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.question {
    width: 45%;
    text-align: left;
}

.question h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.question p {
    font-size: 1.2em;
}

.donation-quote {
    font-size: 1.2em;
    font-style: italic;
}
.paw-logo {
    width: 90px; /* Dimensiunea ajustată pentru a fi mai mică */
    height: auto;
    margin-bottom: 10px;
}
