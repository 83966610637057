.footer {
  background-color: #ffffff;
  color: white;
  padding: 20px;
  text-align: center;
}

.newsletter {
  background-color: #a1722b;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between; /* Aligns sign up and button on the same row */
  align-items: center;
}

.newsletter h2 {
  margin: 0;
  color: white;
  flex-grow: 1; /* Allows the text to take available space */
}

.signup-button {
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  text-decoration: none;
  border-radius: 0;
  cursor: pointer;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: black;
  padding: 20px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-column h3 {
  margin-bottom: 10px;
}

.footer-column a {
  color: black;
  text-decoration: none;
  margin-bottom: 5px;
}

.social-media {
  display: flex;
  align-items: center;
}

.social-media a {
  margin: 0 10px;
}

.footer-bottom {
  background-color: white;
  color: black;
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
}

.icons img {
  width: 24px; /* Smaller icon size */
  height: 24px;
}
