.category-container {
  text-align: center;
  padding: 40px;
}

.category-buttons {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  flex-wrap: wrap;
}

.category {
  position: relative;
  width: 300px;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 3px solid #a1722b;
}

.category:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 15px rgba(255, 215, 0, 0.7);
}

.category-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4); /* Fundalul mai deschis */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Inițial textul este alb */
  font-size: 24px;
  font-weight: bold; /* Îngroșare pentru a face textul mai vizibil */
  opacity: 0;
  transition: opacity 0.3s ease, background 0.3s ease, color 0.3s ease; /* Tranziția și pentru culoare */
  border-radius: 15px;
}

.category:hover .category-overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.2); /* Fundal mai deschis la hover */
  color: red; /* Textul devine roșu la hover */
}

.category-overlay h2 {
  font-size: 28px; /* Mărește fontul */
  font-weight: bold; /* Îngroașă textul */
  color: #a1722b; /* Culoarea textului */
  background-color: white; /* Fundal alb */
  padding: 5px 10px; /* Spațiu în jurul textului */
  border-radius: 8px; /* Colțuri rotunjite pentru fundal */
  transition: color 0.3s ease, text-shadow 0.3s ease; /* Tranziție pentru efect la hover */

}



.category:nth-child(1) {
  background-image: url('./vinyl-records.jpg');
}

.category:nth-child(2) {
  background-image: url('./cd.jpg');
}

.category:nth-child(3) {
  background-image: url('./handcrafted.jpg');
}

.category:nth-child(4) {
  background-image: url('./cleaning-kits.jpg');
}

.category-buttons {
  margin-top: 40px;
}

/* Filter panel styling */
.filter-panel {
  width: 250px;
  padding: 20px;
  background-color: #f8f8f8;
  height: calc(100vh - 70px);
  overflow-y: auto;
  transition: transform 0.3s ease;
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 1000;
  border-right: 2px solid #a1722b;
}

.filter-panel label {
  display: block;
  margin-bottom: 5px;
}

.filter-panel select,
.filter-panel input[type="number"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.filter-panel.hidden {
  transform: translateX(-300px);
}

.filter-panel.visible {
  transform: translateX(0);
}

/* Toggle filter button */
.toggle-filter {
  cursor: pointer;
  background-color: #a1722b;
  color: white;
  padding: 10px;
  font-size: 20px;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1001;
  transition: left 0.3s ease;
}

.toggle-filter.visible {
  left: 290px;
}

.toggle-filter.hidden {
  left: 0;
}

/* Shop content adjusts based on filter panel visibility */
.shop-content {
  padding: 20px;
  margin-left: 270px;
  transition: margin-left 0.3s ease;
}

.shop-content.filter-hidden {
  margin-left: 20px;
}

/* Container for Back button and Sort filter */
.sort-filter-container {
  display: flex;
  justify-content: flex-end; /* Align to the right */
  gap: 20px; /* Space between the back button and sort filter */
  margin-bottom: 20px;
}

/* Styling for Back to Categories button */
.back-button {
  background-color: #a1722b;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #895823;
}

/* Sort filter styling */
.sort-filter {
  display: inline-block;
}

.sort-filter select {
  width: 200px;
  padding: 10px;
  box-sizing: border-box;
}


/* Product list grid */
.product-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
  margin-bottom: 20px;
}

/* Product card styling */
.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  width: 250px;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajustează imaginea să umple containerul păstrând proporțiile */
  border-radius: 8px;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.product-info h3 {
  font-size: 18px;
  margin: 10px 0;
  min-height: 50px;
}

.product-info p {
  margin: 5px 0;
  line-height: 1.5;
}

.product-info .genre {
  font-weight: bold;
  color: black;
}


/* Stil pentru prețul vechi tăiat */
.old-price {
  text-decoration: line-through;
  color: red;
  font-size: 20px;
  margin-right: 10px;
}

/* Stil pentru prețul nou */
.new-price {
  color: green;
  font-weight: bold;
  font-size: 20px;
}


.product-info button {
  background-color: #a1722b;
  color: white;
  padding: 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin: 8px 0;
  font-size: 16px;
  line-height: 18px;
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrare pe orizontală */
  justify-content: center; /* Centrare pe verticală */
  text-align: center; /* Textul să fie centrat */
}

.product-info button:hover {
  background-color: #895823;
}



.product-image {
  position: relative;
  width: 100%;
  height: 250px; /* Înălțime fixă pentru toate imaginile */
  overflow: hidden; /* Ascunde porțiunile din imagine care depășesc containerul */
  display: flex;
  justify-content: center; /* Centrează imaginea pe orizontală */
  align-items: center; /* Centrează imaginea pe verticală */
}

.sold-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: red;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 10;
  opacity: 0.8;
}

/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #a1722b;
  color: white;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination button.active {
  background-color: #895823;
}

.pagination button:hover {
  background-color: #895823;
}

/* Pagination control for selecting number of products */
.pagination-control {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

.pagination-control select {
  padding: 5px;
  font-size: 16px;
}

/* When the filter is hidden, adjust content */
.shop-content.filter-hidden {
  margin-left: 20px;
  transition: margin-left 0.3s ease;
}

.condition-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: bold;
  z-index: 10;
  font-size: 12px;
}
