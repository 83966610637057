.product-detail-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .product-image img {
    width: 300px;
    height: auto;
  }
  
  .product-info {
    text-align: center;
    margin-top: 20px;
  }
  
  .product-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .product-info p {
    margin: 5px 0;
  }
  
  .rating-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .rating-section label {
    margin-bottom: 5px;
  }
  
  .rating-section input {
    width: 50px;
    margin-bottom: 10px;
    padding: 5px;
    text-align: center;
  }
  
  .rating-section button {
    padding: 10px 20px;
    background-color: #a1722b;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .rating-section button:hover {
    background-color: #895823;
  }
  