.notification {
  text-align: center;
}

.notification img {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
}

.notification p {
  margin: 0;
  font-size: 18px;
}
